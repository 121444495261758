import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Title = styled.h1`
  font-size: 5rem;
  text-align: center;
  border: 3px solid black;
  font-weight: bold;
`;

const Header = styled.h1`
  font-size: 2rem;
  text-align: center;
  color: black;
  font-weight: bold;
`;

const TextBlock = styled.h1`
  padding: 10px;
  border: 5px solid blue;
`;

const InstructionBlock = styled.h1`
  padding: 10px;
  border: 5px solid red;
`;

const Textstyle = styled.h1`
  font-size: 3rem;
  text-align: center;
  color: grey;
`;

const Instructions = styled.h2`
  font-size: 3rem;
  text-align: center;
  color: grey;
`;

const Text = (props: any) => {
  const { t } = useTranslation("thecircleoftruth");
  const text = t(props.text);
  const nextCue = t(props.nextcue);

  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      <Title>{props.title}</Title>
      <TextBlock>
        <Header>{"Phone text:"}</Header>
        <Textstyle>{text}</Textstyle>
      </TextBlock>
      <InstructionBlock>
        <Header>{"Next cue:"}</Header>
        <Instructions>{nextCue}</Instructions>
      </InstructionBlock>
    </div>
  );
};

export default Text;
