import * as React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle } from "styled-components";

import Application from "./Application";

import "bootstrap/dist/css/bootstrap.min.css";

import { initializeApp } from "firebase/app";
import "firebase/database";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { initReactI18next } from "react-i18next";

initializeApp({
  apiKey: "AIzaSyAetO-O88y2ZO0GTHRZjrG4x6t3IMf6LUU",
  databaseURL:
    "https://the-circle-of-truth-default-rtdb.europe-west1.firebasedatabase.app/",
});

i18next
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    ns: ["toucan", "thecircleoftruth"],
    backend: {
      projectId: "1882e170-35f1-447f-9b1e-ebe5c9474b0b",
      referenceLng: "en",
    },
    detection: {
      order: ["localStorage", "navigator"],
      lookupLocalStorage: "thecircleoftruthLng",
      caches: ["localStorage"],
      excludeCacheFor: ["cimode"],
    },
  });

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
`;

const SuspenseFallback = () => <h1>Loading...</h1>;

ReactDOM.render(
  <React.Suspense fallback={<SuspenseFallback />}>
    <React.Fragment>
      <GlobalStyle />
      <Application />
    </React.Fragment>
  </React.Suspense>,

  document.getElementById("root")
);
